
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Alert, Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from "../../Constants";

import { Routes } from "../../routes";

export default () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const forgotPassword = (e) => {
    e.preventDefault();

    axios.post(`${BASE_URL}/forgot-password`, {
      email: email
    })
        .then(res => {
          if (res.status === 200) {
            setSuccess('You\'ll receive an email with a link to reset your password.');
          } else {
            setError('Problem requesting password reset, please reach out to support.');
          }
        })
        .catch((err) => {
          setError('Problem requesting password reset, please reach out to support.');
        })
  }

  return (
    <main>
      <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link as={Link} to={Routes.Signin.path} className="text-gray-700">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to sign in
            </Card.Link>
            </p>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3>Forgot your password?</h3>
                <p className="mb-4">Don't fret! Just type in your email and we will send you a code to reset your password!</p>
                <Form onSubmit={forgotPassword}>
                  <div className="mb-4">
                    <Form.Label htmlFor="email">Your Email</Form.Label>
                    <InputGroup id="email">

                      <Form.Control required autoFocus value={email} onChange={e => setEmail(e.target.value) } type="email" placeholder="john@company.com" />
                    </InputGroup>
                  </div>
                  { success ? (
                    <Alert variant={'primary'}>
                      {success}
                    </Alert>
                  ) : (null)}
                  {
                    error ? (
                      <Alert variant={'danger'}>
                        {error}
                      </Alert>
                    ) : (<></>)
                  }
                  <Button variant="primary" type="submit" className="w-100">
                    Recover password
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
