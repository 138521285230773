
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Alert, Image, Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import L from '../../assets/img/brand/l.svg';

import axios from 'axios';
import { BASE_URL } from "../../Constants";

export default () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState();

  const history = useHistory();

  
  const signIn = (e) => {
    e.preventDefault();

    axios.post(`${BASE_URL}/login`, {
      email: email,
      password: password,
    })
        .then(res => {
          if (res.status === 200) {
            localStorage.setItem('token', res.data.access_token);
            localStorage.setItem('user.name', res.data.user.name);
            localStorage.setItem('user.email', res.data.user.email);
            localStorage.setItem('account.encodedId', res.data.user.account.encoded_id);

            axios.interceptors.request.use(function (config) {
              const token = res.data.access_token;
              config.headers.Authorization = `Bearer ${token}`;
          
              return config;
            });

            history.push('/dashboard');
          } else {
            setError('Invalid email or password.');
          }
        })
        .catch((err) => {
          setError('Invalid email or password.');
        })
  }

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <p className="text-center">
            <Image src={L} style={{ width: '240px', margin: '0 auto' }} />
          </p>
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in</h3>
                </div>
                <Form onSubmit={signIn} className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus value={email} onChange={e => setEmail(e.target.value) } required type="email" placeholder="example@company.com" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control value={password} onChange={e => setPassword(e.target.value)} required type="password" placeholder="Password" />
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
                      </Form.Check>
                      <Card.Link as={Link} to={Routes.ForgotPassword.path} className="small text-end">Lost password?</Card.Link>
                    </div>
                  </Form.Group>
                  {
                    error ? (
                      <Alert variant={'danger'}>
                        {error}
                      </Alert>
                    ) : (<></>)
                  }
                  <Button variant="primary" type="submit" className="w-100">
                    Sign in
                  </Button>
                </Form>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Don't have an account?
                    <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                      {` Sign up here `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
