import React , { useEffect, useState, useRef } from "react";
import { InputGroup, Button, Modal, Table, Col, Row, Form, Nav, Pagination } from "@themesberg/react-bootstrap";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { BASE_URL } from "../Constants";

export default (props) => {
    const [totalSelected, setTotalSelected] = useState(3);
    const [retailers, setRetailers] = useState([]);
    const [retailLocations, setRetailLocations] = useState([]);
    const [selectedRetailer, setSelectedRetailer] = useState();
    const [query, setQuery] = useState('');
    const [selectedRetailerIndices, setSelectedRetailerIndices] = useState([]);

    const [selectAll, setSelectAll] = useState(false);

    // Pagination
    const [currentPage, setCurrentPage] = useState();
    const [lastPage, setLastPage] = useState();
    const [perPage, setPerPage] = useState();
    const [total, setTotal] = useState();
    const [from, setFrom] = useState();
    const [to, setTo] = useState();

    const throttling = useRef(false);

    useEffect(() => {
        loadRetailers();
    }, []);

    useEffect(() => {
        setQuery('');
        setSelectedRetailerIndices([]);
        loadRetailerLocations(selectedRetailer, 1) 
    }, [selectedRetailer]);

    useEffect(() => {
        handleDebounceSearch();
    }, [query]);

    const handleDebounceSearch = () => {
        if (throttling.current) {
          return;
        }
  
        if (!query) {
          return;
        }
  
        throttling.current = true;
  
        setTimeout(() => {
          throttling.current = false;
          loadRetailerLocations(selectedRetailer, currentPage, query)
        }, 600);
    }

    const loadRetailers = () => {
        axios.get(`${BASE_URL}/retailers`)
            .then(res => {
            setRetailers(res.data);
        })
    }

    const loadRetailerLocations = (id, page, query) => {
        if (selectedRetailer) {
            let url = `${BASE_URL}/retailers/${id}?page=${page}`;

            if (query && query.length > 0) {
                url = `${url}&q=${query.trim()}`;
            }

            console.log(url);
            axios.get(url)
                .then(res => {
                // const retailers = res.data;
                setRetailLocations(res.data.data);

                setCurrentPage(res.data.current_page);
                setPerPage(res.data.perPage);
                setTotal(res.data.total);
                setFrom(res.data.from);
                setTo(res.data.to);
                setLastPage(res.data.last_page);
            })
        }
    }

    const toggleSelectAll = () => {
        setSelectAll(!selectAll);
    }

    const toggleRetailLocationId = (id) => {
        if (selectedRetailerIndices.includes(id)) {
            setSelectedRetailerIndices(selectedRetailerIndices.filter(sri => sri !== id));
            return;
        }

        setSelectedRetailerIndices([...selectedRetailerIndices, id]);
    }

    const showPaginationNumbers = () => {
        const maxVisible = 10;

        if (lastPage < maxVisible) {
            return ([...Array(lastPage).keys()].map((p, index) => (<Pagination.Item key={`pag-${index}`} onClick={() => {loadRetailerLocations(selectedRetailer, index+1)}} active={currentPage === (index+1)}>{index+1}</Pagination.Item>)));
        }

        if ((currentPage + maxVisible) < lastPage) {
            let arrayKeys = [];

            for (let i = currentPage; i <= (currentPage + maxVisible); i++) {
                arrayKeys.push(i);
            }

            return arrayKeys.map(k => (<Pagination.Item key={`pag-${k}`} onClick={() => {loadRetailerLocations(selectedRetailer, k)}} active={currentPage === (k)}>{k}</Pagination.Item>));
        }

        if (lastPage - (currentPage + maxVisible) < maxVisible) {
            let arrayKeys = [];
            for (let i = (lastPage - maxVisible); i <= lastPage; i++) {
                arrayKeys.push(i);
            }

            return arrayKeys.map(k => (<Pagination.Item key={`pag-${k}`} onClick={() => {loadRetailerLocations(selectedRetailer, k)}} active={currentPage === (k)}>{k}</Pagination.Item>));
        }
    }

    return (
        <Modal as={Modal.Dialog} centered show={props.show} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Import Retailers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="pt-1 pb-3">
                    <Col>
                        <Form.Select onChange={(e) => { setSelectedRetailer(e.target.value) }}>
                            <option defaultValue>Retailers</option>
                            { retailers.map(r => (<option key={`rl-${r.id}`} value={r.id}>{r.name}</option>) ) }
                        </Form.Select>
                    </Col>
                    <Col>
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                            <Form.Control type="text" value={query} onChange={(e) => {setQuery(e.target.value)}} placeholder="Search" />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="import-table">
                    { retailLocations && retailLocations.length > 0 ? 
                        (<Table responsive className="table-centered table-striped table-hover table-nowrap">
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    {/* <th className="col-sm-1"><Form.Check onChange={() => {toggleSelectAll()}} /></th> */}
                                    <th>Store</th>
                                    <th>Store Number</th>
                                    <th>Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                { retailLocations.map((rl) => {
                                    return (
                                        <tr key={`rl-${rl.id}`}>
                                            <td className="col-sm-1"><Form.Check checked={selectedRetailerIndices.includes(rl.id) || selectAll } onChange={() => {toggleRetailLocationId(rl.id)}} /></td>
                                            <td>{rl.name}</td>
                                            <td>{rl.store_number}</td>
                                            <td>{rl.address} {rl.address2} {rl.city}, {rl.state} {rl.zip}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>) : (<></>)
                    }
                </Row>
                <Row className="pt-3 pb-3 px-3 border-0 d-lg-flex align-items-center justify-content-between">
                    { retailLocations && retailLocations.length > 0 ?
                        ( <Nav>
                        <Pagination className="mb-2 mb-lg-0">
                            <Pagination.Prev disabled={(currentPage <= 1)}  onClick={() => {loadRetailerLocations(selectedRetailer, currentPage-1)}}>
                                Previous
                            </Pagination.Prev>
                          {
                              showPaginationNumbers()
                            // [...Array(lastPage).keys()].map((p, index) => (<Pagination.Item onClick={() => {loadRetailerLocations(selectedRetailer, index+1)}} active={currentPage === (index+1)}>{index+1}</Pagination.Item>))
                          }
                            <Pagination.Next disabled={(currentPage >= lastPage)} onClick={() => {loadRetailerLocations(selectedRetailer, currentPage+1)}}>
                                Next
                            </Pagination.Next>
                        </Pagination>
                    </Nav>) : (<></>)}
                </Row>
                {
                    (!retailLocations || retailLocations.length === 0 && !selectedRetailer) ? 
                    (
                        <Row className="justify-content-center pb-4">
                            Select a retailer to begin.
                        </Row>
                    ) : (<></>)
                }
                {
                    (retailLocations.length === 0 && query.length > 0 && selectedRetailer) ?
                    (
                        <Row className="justify-content-center pb-4">
                            No results found.
                        </Row>
                    ) : (<></>)
                }
                <Row>
                    { retailLocations && retailLocations.length > 0 ? 
                        (<small className="fw-bold">
                            Showing <b>{from}-{to}</b> out of <b>{total}</b> retail locations
                        </small>) : (<></>)
                    }
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link">Import All</Button>
                <small><span>Importing <strong>{selectAll ? total : selectedRetailerIndices.length}</strong> locations</span></small>
                <Button variant="primary">Import</Button>
                <Button variant="white">Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
}