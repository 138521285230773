
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faBuilding, faEnvelope, faUnlockAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Alert, Image, Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import L from '../../assets/img/brand/l.svg';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import { BASE_URL } from "../../Constants";

export default () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [accountName, setAccountName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState();

  const history = useHistory();

  const signUp = (e) => {
    e.preventDefault();

    axios.post(`${BASE_URL}/register`, {
      email: email,
      name: name,
      account_name: accountName,
      password: password,
      password_confirmation: confirmPassword
    })
        .then(res => {
          if (res.status === 201) {
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('user.name', res.data.user.name);
            localStorage.setItem('user.email', res.data.user.email);
            localStorage.setItem('account.encodedId', res.data.encoded_id);

            axios.interceptors.request.use(function (config) {
              const token = res.data.token;
              config.headers.Authorization = `Bearer ${token}`;
          
              return config;
          });


            history.push('/locations');
          } else {
            alert('error!');
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            console.log(err.response);
            setErrors(err.response.data.errors);
          }
        })
  }

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <p className="text-center">
            <Image src={L} style={{ width: '240px', margin: '0 auto' }} />
          </p>
          <Row className="justify-content-center form-bg-image" style={{ background: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Create an account</h3>
                </div>
                <Form onSubmit={signUp} className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control value={email} onChange={ e => setEmail(e.target.value) } autoFocus required type="email" placeholder="example@company.com" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="name" className="mb-4">
                    <Form.Label>Your Name</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <Form.Control value={name} onChange={ e => setName(e.target.value) } autoFocus required type="text" placeholder="Jane Doe" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="account_name" className="mb-4">
                    <Form.Label>Company Name</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faBuilding} />
                      </InputGroup.Text>
                      <Form.Control value={accountName} onChange={ e => setAccountName(e.target.value) } autoFocus required type="text" placeholder="Acme Co" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Your Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control value={password} onChange={ e => setPassword(e.target.value) } required type="password" placeholder="Password" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control value={confirmPassword} onChange={ e => setConfirmPassword(e.target.value) } required type="password" placeholder="Confirm Password" />
                    </InputGroup>
                  </Form.Group>
                  <FormCheck type="checkbox" className="d-flex mb-4">
                    <FormCheck.Input required id="terms" className="me-2" />
                    <FormCheck.Label htmlFor="terms">
                      I agree to the <Card.Link>terms and conditions</Card.Link>
                    </FormCheck.Label>
                  </FormCheck>


                  {
                    errors ? (
                      <Alert variant={'danger'}>
                        { Object.keys(errors).map((error, index) => (
                          <><span key={`err-${index}`}>{errors[error][0]}</span><br/></>)) }
                      </Alert>
                    ) : (<></>)
                  }

                  <Button variant="primary" type="submit" className="w-100">
                    Sign up
                  </Button>
                </Form>

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account?
                    <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                      {` Login here `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
