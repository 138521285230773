import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { GeneralInfoForm } from "../components/Forms";
import { SketchPicker } from 'react-color';
import { Button, Breadcrumb, Card, Form, Row, Col } from '@themesberg/react-bootstrap';
import axios from 'axios';
import { BASE_URL } from "../Constants";
import { ToastContainer, toast } from 'react-toastify';

export default () => {

  const [color, setColor] = useState('#ff0000');
  const [showPicker, setShowPicker] = useState(false);

  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const [account, setAccount] = useState({
    theme_color: '#000',
    name: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    auto_geolocate: true,
    default_center_lat: '',
    default_center_lng: '',
    default_image_url: '',
    default_zoom: '',
    show_sb_logo: true
  });

  const loadAccountSettings = () => {
    axios.get(`${BASE_URL}/account`)
      .then(res => {
        const accountData = res.data;
        setAccount(accountData);

        console.log(accountData);
        setColor(accountData.theme_color);
      })
  }
  
  useEffect(() => {
    loadAccountSettings();
  }, []);

  const togglePicker = () => {
    setShowPicker(!showPicker);
  }

  const pickerChange = (color) => {
    setColor(color.hex);

    setAccount({...account, theme_color: color.hex});
  }

  const uploadImage = async (event) => {
    if (event.target.files && event.target.files[0]) {
      setIsUploadingImage(true);
      let img = event.target.files[0];

      const formData = new FormData();
      formData.append("image", img);
      try {
        const response = await axios({
          method: "post",
          url: `${BASE_URL}/account/image`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });

        console.log(response.data.url);
        setAccount({...account, default_image_url: response.data.url});
        setIsUploadingImage(false);
      } catch(error) {
        console.log(error);
        setIsUploadingImage(false);
      }
    }
  }

  const handleInputChange = (e) => {
    setColor(e.target.value);
    setAccount({...account, theme_color: e.target.value});
  }

  const formSubmit = (e) => {
    e.preventDefault();

    axios.patch(`${BASE_URL}/account`, {
      name: account.name,
      address: account.address,
      address2: account.address2,
      city: account.city,
      state: account.state,
      zip: account.zip,
      default_image_url: account.default_image_url,
      theme_color: account.theme_color,
      auto_geolocate: account.auto_geolocate
    })
        .then(res => {
          console.log(res);
          toast('🎉 Account settings updated!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    })
  }


  return (
    <>
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
          {/* Same as */}
          <ToastContainer />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
              <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                  <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                  <Breadcrumb.Item active>Settings</Breadcrumb.Item>
              </Breadcrumb>
              <h4>Settings</h4>
              <p className="mb-0">Your account preferences.</p>
          </div>
      </div>

      <Form onSubmit={formSubmit}>
        <Row>
          <Col xs={12} xl={8}>
            <Card>
              <Card.Body>
                <Row className="pt-2 pb-1">
                  <h5 className="mb-4">General information</h5>
                    <Row>
                      <Col sm={8} className="mb-3">
                        <Form.Group id="name">
                          <Form.Label>Name</Form.Label>
                          <Form.Control required value={account.name} onChange={(e) => { setAccount({...account, name: e.target.value}) }} type="text" placeholder="Enter name" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <h5 className="my-4">Address</h5>
                    <Row className="pt-1 pb-1">
                      <Col sm={8} className="mb-3">
                        <Form.Group id="address">
                          <Form.Label>Street Address</Form.Label>
                          <Form.Control required value={account.address} onChange={(e) => { setAccount({...account, address: e.target.value}) }}  type="text" placeholder="Enter address" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8} className="mb-3">
                        <Form.Group id="address2">
                          <Form.Control type="text" value={account.address2} onChange={(e) => { setAccount({...account, address2: e.target.value}) }} placeholder="Address Line 2" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4} className="mb-3">
                        <Form.Group id="city">
                          <Form.Label>City</Form.Label>
                          <Form.Control required value={account.city} onChange={(e) => { setAccount({...account, city: e.target.value}) }} type="text" placeholder="City" />
                        </Form.Group>
                      </Col>
                      <Col sm={4} className="mb-3">
                        <Form.Group className="mb-2">
                          <Form.Label>Select state</Form.Label>
                          <Form.Select id="state" defaultValue="0" value={account.state} onChange={(e) => { setAccount({...account, state: e.target.value}) }} >
                            <option value="0">State</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group id="zip">
                          <Form.Label>ZIP</Form.Label>
                          <Form.Control required type="tel" value={account.zip} onChange={(e) => { setAccount({...account, zip: e.target.value}) }}  placeholder="ZIP" />
                        </Form.Group>
                      </Col>
                    </Row>
                </Row>
                <Row className="pt-5 pb-5">
                  <Form.Group>
                    <h5>Logo</h5>
                    <Row>
                      { isUploadingImage ? (
                      <Col sm={3}>
                        <div className="loader-element lds-ring"><div></div><div></div><div></div><div></div></div>
                      </Col>) : (<></>) }
                      {
                        account.default_image_url ?
                        (<Col sm={2} className="align-items-center"><div style={{backgroundImage: `url(${account.default_image_url})`, backgroundPosition: 'center', backgroundSize: '100%', backgroundRepeat: 'none', borderWidth: 2, borderStyle: 'solid', borderColor: '#c0c0c0', borderRadius: '50px', width: '100px', height: '100px'}} /></Col>) : (<></>) 
                      }
                      <Col sm={7} className="pt-3">
                        <Form.Control type="file" onChange={uploadImage} />
                        <Form.Text className="text-muted">
                          This image will display on the location detail. We recommend a square image at least 150x150 pixels.
                        </Form.Text>
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                <Row className="pt-5 pb-5">
                  <Form.Group>
                    <h5>Color</h5>
                    <Row>
                      <Col xs={1}>
                        <div onClick={ () => { togglePicker() } }>
                          <div style={{ borderRadius: 8, borderWidth: 1, borderColor: '#c0c0c0', width: 44, height: 44, backgroundColor: color }} />
                        </div>
                            {
                              showPicker ? (
                                <div style={{ position: 'absolute', zIndex: 2, userSelect: 'none'}}>
                                  <div style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px'}} onClick={togglePicker} />
                                    <SketchPicker color={color} onChange={(newColor) => pickerChange(newColor)} />
                                </div>) : (<></>)
                            }
                      </Col>
                      <Col xs={3}>
                        <Form.Group id="color">
                          <Form.Control xs={1} type="text" value={color} onChange={handleInputChange} />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                <Row className="pt-5 pb-2">
                    <Form.Group>
                      <h5>Settings</h5>
                      <Form.Group>
                        <Form.Check type="checkbox" label="Automatically request user's location" checked={!!account.auto_geolocate} onChange={(e) => { setAccount({...account, auto_geolocate: !account.auto_geolocate}) }} />
                      </Form.Group>
                    </Form.Group>
                </Row>
                <Row className="pt-2 pb-2">
                  <div className="mt-3">
                    <Button variant="primary" type="submit">Save Settings</Button>
                  </div>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};
