import React, { useEffect, useState } from "react";
import Preloader from '../../components/Preloader';
import { Container, Image, Button, Breadcrumb, Card, Row, Col } from '@themesberg/react-bootstrap';

import axios from "axios";
import { BASE_URL } from "../../Constants";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import ShopifyPlans from '../components/ShopifyPlans';

import L from '../../assets/img/brand/l.svg';
import sbFooter from '../../assets/img/brand/sb-footer.svg';

export default () => {
    const history = useHistory();
    const [showPreloader, setShowPreloader] = useState(false);
    const [shopifyShop, setShopifyShop] = useState();

    useEffect(() => {
        loadAcctDetails();
    }, []);

    const loadAcctDetails = () => {
        const token = localStorage.getItem('token');
        console.log('t: ', token);
        axios.interceptors.request.use(function (config) {
            config.headers.Authorization = `Bearer ${token}`;
        
            return config;
        });

        axios.get(`${BASE_URL}/account`)
            .then(res => {
                const accountData = res.data;
                setShopifyShop(accountData.shopify_shop);
                console.log(accountData.shopify_shop);
            })
    }

    const subscribe = (plan) => {
        setShowPreloader(true);

        const token = localStorage.getItem('token');
        axios.interceptors.request.use(function (config) {
            config.headers.Authorization = `Bearer ${token}`;
        
            return config;
        });

        axios.post(`${BASE_URL}/shopify/subscribe`, { plan: plan })
            .then(res => {
                if (res.status === 200) {
                    const redirectUrl = res.data.redirect_url;
                    window.location = redirectUrl;
                    // next;

                    // history.push('/shopify/page');
                } else {
                    console.error('error');
                    alert('Error adding Stockbird plan to your Shopify account.')
                }
            })
            .catch((err) => {
                console.error(err);
            })
    }

    return (
        <>
        { showPreloader ? (<Preloader show={true} />) : (
        <Container>
            <br/>
            <p className="text-center">
                <Image src={L} style={{ width: '240px', margin: '0 auto' }} />
            </p>
            <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="pb-0 text-center">
                    <h5>Stockbird Plans</h5>
                    <div className="mb-3">Please select a plan that fits your needs, you can always change this later. </div>
                    <ShopifyPlans subscribe={subscribe} actionLabel={'Get Started'}/>
                    {/* <Row>
                    <Col sm={1}></Col>
                    <Col sm={3} className="mt-2">
                        <Card>
                        <Card.Body>
                            <Row className="pt-1 pb-3">
                                <h5>Standard 100</h5>
                                <h4>$19<span className="per_mo">/mo</span></h4>
                                <ul className="plan_features">
                                    <li>
                                        <strong>7-day</strong> free trial
                                    </li>
                                    <li>
                                        Display up to <strong>100</strong> locations in your store locator
                                    </li>
                                    <li>
                                        Search heatmap
                                    </li>
                                </ul>
                                <br />
                            </Row>
                            <Row>
                                <Button variant="primary" onClick={() => {subscribe('standard')}}>Get Started</Button>
                            </Row>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={4}>
                        <Card>
                            <Card.Body>
                            <Row className="pt-1 pb-5">
                                <h5>Pro 1,000</h5>
                                <h4>$29<span className="per_mo">/mo</span></h4>
                                <ul className="plan_features">
                                    <li>
                                        <strong>7-day</strong> free trial
                                    </li>
                                    <li>
                                        Display up to <strong>1000</strong> locations in your store locator
                                    </li>
                                    <li>
                                        Search heatmap
                                    </li>
                                </ul>
                            </Row>
                            <Row>
                                <Button variant="primary" onClick={() => {subscribe('pro')}}>Get Started</Button>
                            </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={3} className="mt-2">
                        <Card>
                            <Card.Body>
                            <Row className="pt-1 pb-3">
                                <h5>Enterprise 5,000</h5>
                                <h4>$49<span className="per_mo">/mo</span></h4>
                                <ul className="plan_features">
                                    <li>
                                        <strong>7-day</strong> free trial
                                    </li>
                                    <li>
                                        Display up to <strong>5000</strong> locations in your store locator
                                    </li>
                                    <li>
                                        Search heatmap
                                    </li>
                                </ul>
                            </Row>
                            <Row>
                                <Button variant="primary" onClick={() => {subscribe('enterprise')}}>Get Started</Button>
                            </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    </Row> */}
                    <br />
                    {
                        shopifyShop ? (
                            <div className="text-center mb-4">
                                <Button variant="outline-primary" onClick={() => { window.location = `https://${shopifyShop}/admin` }}><FontAwesomeIcon icon={faArrowAltCircleLeft} />&nbsp;Return to Shopify Admin</Button>
                            </div>
                        ) : (null)
                    }
                </Card.Body>
            </Card>
            </Container>
            )}
            <div className="text-center">
                <Image src={sbFooter} style={{ width: '52px', margin: '0 auto' }} />
            </div>
        </>
    );
}