import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BASE_URL } from '../../Constants';
import { faLocationCrosshairs, faHome, faMapLocation, faTags } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb, Button, Card, Form, Row, Col } from '@themesberg/react-bootstrap';
import { LocationForm } from "../../components/LocationForm";
import GoogleMapReact from 'google-map-react';
import TagList from '../../components/TagList';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default (props) => {
  const defaultProps = {
    center: {
      lat: 42.877742,
      lng:-97.380979
    },
    zoom: 11
  };
  const [mode, setMode] = useState('new');
  const [existingId, setExistingId] = useState();
  const [loading, setLoading] = useState(true);
  const [lat, setLat] = useState(defaultProps.center.lat);
  const [lng, setLng] = useState(defaultProps.center.lng);
  const [locationSaved, setLocationSaved] = useState(false);

  // const [name, setName] = useState('');
  // const [address, setAddress] = useState('');
  // const [address2, setAddress2] = useState('');
  // const [city, setCity] = useState('');
  // const [state, setState] = useState('');
  // const [zip, setZip] = useState('');

  const [formData, setFormData] = useState({
    name: null,
    address: null,
    address2: null,
    city: null,
    state: null,
    zip: null,
    phone: null
  });

  const [formKey, setFormKey] = useState(new Date().getTime());

  useEffect(() => {
    if (props.match.params.id != null) {
      setExistingId(props.match.params.id);
      setMode('edit');
      loadLocationData(props.match.params.id);
    } else {
      setLoading(false);
    }
  }, []);

  const loadLocationData = async (id) => {
    let url = `${BASE_URL}/locations/${id}}`;
    setLoading(true);
      
    await axios.get(url)
      .then(res => {
        const location = res.data;
        console.log(location);

        setFormData({
          name: location.name,
          address: location.address,
          address2: location.address2,
          city: location.city,
          state: location.state,
          zip: location.zip,
          phone: location.phone
        });
        
        setLat(location.lat);
        setLng(location.lng);
        setLoading(false);
    }).catch((e) => { setLoading(false)});
    

  };

  const Marker = (props) => {
    return (
      <div style={{width: 50, height: 50, borderWidth: 1, borderColor: 'red'}}>
        <FontAwesomeIcon icon={faLocationCrosshairs} color={'#ff0000'} style={{width: 30, height: 30, marginLeft: -15, marginTop: -15}} />
      </div>
    );
  }

  const onMarkerDragEnd = (e) => {
    setLat(e.center.lat());
    setLng(e.center.lng());
  }

  const showOnMap = () => {
    axios.post(`${BASE_URL}/locations/geocode`, {
      address: `${formData.address} ${formData.address2} ${formData.city} ${formData.state} ${formData.zip}`
    })
        .then(res => {
          const geocode = res.data;
          setLat(geocode.lat);
          setLng(geocode.lng);
    })
  }

  const formValuesChange = (v) => {
    setFormData(v);
  }

  const resetForm = () => {
    setFormData(
      {
        name: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phone: ''
      }
    )
    setFormKey(new Date().getTime());
  }

  const saveButtonPressed = () => {
    if (mode == "new") {
      axios.post(`${BASE_URL}/locations`, {
        name: formData.name,
        address: formData.address,
        address2: formData.address2,
        city: formData.city,
        state: formData.state,
        zip: formData.state,
        phone: formData.phone
      })
          .then(res => {
            console.log(res);
            toast('🎉 Location saved!', {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
            resetForm();
      })
    } else if (mode == "edit") {
      axios.patch(`${BASE_URL}/locations/${existingId}`, {
        name: formData.name,
        address: formData.address,
        address2: formData.address2,
        city: formData.city,
        state: formData.state,
        zip: formData.state,
        phone: formData.phone
      })
          .then(res => {
            console.log(res);
            toast('🎉 Location updated!', {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
            // resetForm();
      })
    }
    
  }

  // const handleCloseDefault = () => {}

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        {/* Same as */}
        <ToastContainer />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
              <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                  <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                  <Breadcrumb.Item href={"#locations"}>Locations</Breadcrumb.Item>
                  <Breadcrumb.Item active>{ mode == 'new' ? 'New Location' : 'Edit Location' }</Breadcrumb.Item>
              </Breadcrumb>
              <h4>{ mode == 'new' ? `New Location` : `Edit Location` }</h4>
              <p className="mb-0">{ mode == 'new' ? `Add a new location to your store locator.` : `Edit location.`}</p>
          </div>
      </div>
      <Row>
        <Col xs={12} xl={7} lg={7}>
          { loading ? (<>Loading...</>) : (
          <LocationForm mode={mode} key={formKey} value={formData} {...formData} onValueChange={(v) => { formValuesChange(v) }} savePressed={saveButtonPressed} />
          )}
        </Col>
        <Col xs={12} xl={5} lg={5}>

        { loading ? (null) : (
          <>
          <Row>
          <Card>
            <Card.Body>
              <Row>
                <h5><FontAwesomeIcon icon={faMapLocation} color={'#d0d0d0'} />&nbsp;Map Location</h5>
                <Col className="mb-3">
                  <div style={{ height: '400px', width: '100%' }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: 'AIzaSyC7BhJXnGGR0K2MQjyoCci_WXGMPL_YjSY' }}
                      defaultCenter={defaultProps.center}
                      defaultZoom={defaultProps.zoom}
                      onDragEnd={onMarkerDragEnd}
                      center={{lat: lat, lng: lng}}
                      zoom={15}
                    >
                      {/* <div
                        lat={42.877742}
                        lng={-97.380979}
                        style={{width: 50, height: 50, backgroundColor: '#fff'}}
                      >New Location</div> */}

                      <Marker 
                        draggable={true}
                        />
                    </GoogleMapReact>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <Form.Group id="latitude">
                    <Button variant="primary" type="submit" onClick={() => { showOnMap() }}><FontAwesomeIcon icon={faMapLocation} className="me-1" /> Show on map</Button>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={4} className="mb-3">
                  <Form.Group id="latitude">
                    <Form.Label>Latitude</Form.Label>
                    <Form.Control disabled type="text" placeholder="Latitude" value={lat} />
                  </Form.Group>
                </Col>
                <Col sm={4} className="mb-3">
                  <Form.Group id="longitude">
                    <Form.Label>Longitude</Form.Label>
                    <Form.Control disabled type="text" placeholder="Longitude" value={lng} />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          </Row>
          </>)}
          <Row>&nbsp;</Row>
          {/* <Row>
            <Card>
              <Card.Body>
                <h5><FontAwesomeIcon icon={faTags} color={'#d0d0d0'} />&nbsp;Tags</h5>
                <TagList />
              </Card.Body>
            </Card>
          </Row> */}
        </Col>
      </Row>
        
    </>
  );
};
