import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

export default (props = {}) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    let history = useHistory();

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (token) {
            setIsLoggedIn(true);
            history.replace('/dashboard');
        } else {
            setIsLoggedIn(false);
            history.replace('/sign-in');
        }
    }, []);

    return (<></>);
}
