import React from 'react';

import { Container, Image, Button, Breadcrumb, Card, Row, Col } from '@themesberg/react-bootstrap';

export default (props) => {
  return (
    <>
        { props && props.requestInProgress && props.requestInProgress == true ? (
        <>
            <div className="text-center">
                <div className="loader-element lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        </>) : (
            <Row>
                <Col sm={1}></Col>
                <Col sm={3} className="mt-2">
                    <Card>
                    <Card.Body>
                        <Row className="pt-1 pb-3">
                            <h5>Standard 100</h5>
                            <h4>$9<span className="per_mo">/mo</span></h4>
                            <ul className="plan_features">
                                <li>
                                    <strong>30-day</strong> free trial
                                </li>
                                <li>
                                    Display up to <strong>100</strong> locations in your store locator
                                </li>
                                <li>
                                    Search heatmap
                                </li>
                            </ul>
                            <br />
                        </Row>
                        <Row>{ props.currentPlan && props.currentPlan == 'standard' ? (
                                <span>Your Current Plan</span>
                                ) : (
                                <Button variant="primary" onClick={() => {props.subscribe('standard')}}>{props.actionLabel}</Button>
                            )}
                        </Row>
                    </Card.Body>
                    </Card>
                </Col>
                <Col sm={4}>
                    <Card>
                        <Card.Body>
                        <Row className="pt-1 pb-5">
                            <h5>Pro 1,000</h5>
                            <h4>$19<span className="per_mo">/mo</span></h4>
                            <ul className="plan_features">
                                <li>
                                    <strong>30-day</strong> free trial
                                </li>
                                <li>
                                    Display up to <strong>1000</strong> locations in your store locator
                                </li>
                                <li>
                                    Search heatmap
                                </li>
                            </ul>
                        </Row>
                        <Row>
                        { props.currentPlan && props.currentPlan == 'pro' ? (
                                <span>Your Current Plan</span>
                                ) : (
                                <Button variant="primary" onClick={() => {props.subscribe('pro')}}>{props.actionLabel}</Button>
                            )}
                        </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={3} className="mt-2">
                    <Card>
                        <Card.Body>
                        <Row className="pt-1 pb-3">
                            <h5>Enterprise 5,000</h5>
                            <h4>$39<span className="per_mo">/mo</span></h4>
                            <ul className="plan_features">
                                <li>
                                    <strong>30-day</strong> free trial
                                </li>
                                <li>
                                    Display up to <strong>5000</strong> locations in your store locator
                                </li>
                                <li>
                                    Search heatmap
                                </li>
                            </ul>
                        </Row>
                        <Row>
                        { props.currentPlan && props.currentPlan == 'enterprise' ? (
                                <span>Your Current Plan</span>
                                ) : (
                                <Button variant="primary" onClick={() => {props.subscribe('enterprise')}}>{props.actionLabel}</Button>
                            )}
                        </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        )}
    </>
  );
};
