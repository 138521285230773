import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Preloader from '../components/Preloader';

import axios from "axios";
import { BASE_URL } from "../Constants";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default () => {
    let history = useHistory();
    let query = useQuery();

    useEffect(() => {
        const subId = query.get('sub_id');
        if (subId) {
            console.log('sub_id', subId);
            axios.post(`${BASE_URL}/account/subscription`, {
                subscription_id: subId
            })
                .then(res => {
                    if (res.status === 200) {
                        history.push('/settings/subscription');
                    } else {
                        console.error('error');
                    }
                })
                .catch((err) => {
                    console.error(err);
                })
        } else {
            alert('no sub id');
        }
    }, []);

    return (
        <>
            <Preloader />
            Updating your subscription details...
        </>
    );
}