
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Alert, Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from "../../Constants";

import { Routes } from "../../routes";


export default () => {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  useEffect(() => {
    const [hash, query] = window.location.href.split('#')[1].split('?');
    const param = Object.fromEntries(new URLSearchParams(query));
    setToken(param['token']);
  }, []);

  const resetPassword = (e) => {
    e.preventDefault();

    axios.post(`${BASE_URL}/reset-password`, {
      email: email,
      password: password,
      token: token
    })
        .then(res => {
          if (res.status === 200) {
            setSuccess('Your password has been reset, you can now login.');
          } else {
            setError('Problem resetting password, please reach out to support.');
          }
        })
        .catch((err) => {
          setError('Problem resetting password, please reach out to support.');
        })
  }

  return (
    <main>
      <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link as={Link} to={Routes.Signin.path} className="text-gray-700">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to sign in
              </Card.Link>
            </p>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3 className="mb-4">Reset password</h3>
                <Form onSubmit={resetPassword}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus value={email} onChange={e => setEmail(e.target.value) } required type="email" placeholder="example@company.com" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Your Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required value={password} onChange={e => setPassword(e.target.value) } type="password" placeholder="Password" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required value={confirmPassword} onChange={e => setConfirmPassword(e.target.value) } type="password" placeholder="Confirm Password" />
                    </InputGroup>
                  </Form.Group>
                  { success ? (
                    <Alert variant={'primary'}>
                      {success}
                      <Card.Link as={Link} to={Routes.Signin.path} className="text-gray-700">
                        Sign in
                      </Card.Link>
                    </Alert>
                  ) : (null)}
                  {
                    error ? (
                      <Alert variant={'danger'}>
                        {error}
                      </Alert>
                    ) : (<></>)
                  }
                  <Button variant="primary" type="submit" className="w-100">
                    Reset password
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
