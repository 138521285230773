
export const Routes = {
    // pages
    // Presentation: { path: "/" },
    AuthController: { path: "/" },
    Tags: { path: "/tags" },
    Locations: { path: "/locations" },
    NewLocation: { path: "/locations/new" },
    LocationDashboard: { path: "/dashboard" },
    LocationEdit: { path: "/locations/:id"},
    // DashboardOverview: { path: "/dashboard/overview" },
    GeneralSettings: { path: "/settings/general" },
    Embed: { path: "/settings/embed-code" },
    Subscription: { path: "/settings/subscription" },
    Subscribed: { path: "/settings/subscribed" },
    Upgrade: { path: "/upgrade" },
    Billing: { path: "/examples/billing" },
    Invoice: { path: "/examples/invoice" },
    Signin: { path: "/sign-in" },
    Signup: { path: "/sign-up" },
    ForgotPassword: { path: "/forgot-password" },
    ResetPassword: { path: "/reset-password" },
    Lock: { path: "/examples/lock" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },
    ShopifyAuth: { path: "/shopify/auth" },
    ShopifyPlan: { path: "/shopify/plan" },
    ShopifyPage: { path: "/settings/shopify/page" },
    ShopifySubscribed: { path: "/shopify/subscribed" },

    // docs
    DocsOverview: { path: "/documentation/overview" },
    DocsDownload: { path: "/documentation/download" },
    DocsQuickStart: { path: "/documentation/quick-start" },
    DocsLicense: { path: "/documentation/license" },
    DocsFolderStructure: { path: "/documentation/folder-structure" },
    DocsBuild: { path: "/documentation/build-tools" },
    DocsChangelog: { path: "/documentation/changelog" },

    // components
    Accordions: { path: "/components/accordions" },
    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets" }
};