
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Row, Col, Nav, Form, Image, Navbar, Dropdown, Container, ListGroup, InputGroup } from '@themesberg/react-bootstrap';

import NOTIFICATIONS_DATA from "../data/notifications";
import Profile2 from "../assets/img/team/profile-picture-2.jpg";
import { useHistory } from "react-router-dom";
import Gravatar from 'react-gravatar'


export default (props) => {
  let history = useHistory();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    const userName = localStorage.getItem('user.name');
    setName(userName);

    const email = localStorage.getItem('user.email');
    setEmail(email);
  }, []);

  const logOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user.name');
    localStorage.removeItem('user.email');
    localStorage.removeItem('account.encodedId');
    history.replace('/sign-in');
  }

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0" style={{float: 'right'}}>
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center"></div>
          <Nav className="align-items-center">
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Gravatar email={email} className="user-avatar md-avatar rounded-circle" />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">{name}</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                
                <Dropdown.Item onClick={ (e) => { window.location.href = 'mailto:hello@giddyapp.io'; e.preventDefault() }} className="fw-bold">
                  <FontAwesomeIcon icon={faUserShield} className="me-2" /> Support
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item onClick={() => { logOut() }} className="fw-bold">
                  <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
