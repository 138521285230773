
import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faLocation, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, InputGroup } from '@themesberg/react-bootstrap';

export const LocationForm = ({ mode, name, address, address2, city, state, zip, phone, onValueChange, savePressed }) => {
  const [nameValue, setNameValue] = useState('');
  const [addressValue, setAddressValue] = useState('');
  const [address2Value, setAddress2Value] = useState('');
  const [cityValue, setCityValue] = useState('');
  const [stateValue, setStateValue] = useState('')
  const [zipValue, setZipValue] = useState('');
  const [phoneValue, setPhoneValue] = useState('');

  useEffect(() => {
    setNameValue(name);
    setAddressValue(address);
    setAddress2Value(address2);
    setCityValue(city);
    setStateValue(state);
    setZipValue(zip);
    setPhoneValue(phone);
  }, []);

  useEffect(() =>{ 
    updatedValue()
  }, [nameValue, addressValue, address2Value, cityValue, stateValue, zipValue, phoneValue]);

  const updatedValue = () => {
    onValueChange({
      name: nameValue,
      address: addressValue,
      address2: address2Value,
      city: cityValue,
      state: stateValue,
      zip: zipValue,
      phone: phoneValue
    });
  }

  const formSubmit = (e) => {
    e.preventDefault();
    savePressed();
  }

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4"><FontAwesomeIcon icon={faLocationArrow} color={'#d0d0d0'} />&nbsp;General information</h5>
        <Form onSubmit={formSubmit}>
          <Row>
            <Col sm={8} className="mb-3">
              <Form.Group id="name">
                <Form.Label>Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter name" value={name} onChange={e => setNameValue(e.target.value)}/>
              </Form.Group>
            </Col>
          </Row>
          <h5 className="my-4">Address</h5>
          <Row>
            <Col sm={8} className="mb-3">
              <Form.Group id="address">
                <Form.Label>Street Address</Form.Label>
                <Form.Control required type="text" placeholder="Enter address" value={address} onChange={e => setAddressValue(e.target.value)} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={8} className="mb-3">
              <Form.Group id="address2">
                <Form.Control type="text" placeholder="Address Line 2" value={address2} onChange={e => setAddress2Value(e.target.value)} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4} className="mb-3">
              <Form.Group id="city">
                <Form.Label>City</Form.Label>
                <Form.Control required type="text" placeholder="City" value={city} onChange={e => setCityValue(e.target.value)} />
              </Form.Group>
            </Col>
            <Col sm={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Select state</Form.Label>
                <Form.Select id="state" defaultValue="0" value={state} onChange={e => setStateValue(e.target.value)}>
                  <option value="0">State</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </Form.Select>
              </Form.Group>
            </Col>
        </Row>
        <Row>
          <Col sm={4} className="mb-3">
            <Form.Group id="zip">
              <Form.Label>ZIP</Form.Label>
              <Form.Control required type="tel" placeholder="ZIP" value={zip} onChange={e => setZipValue(e.target.value)} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={4} className="mb-3">
            <Form.Group id="phone">
              <Form.Label>Phone number (optional)</Form.Label>
              <Form.Control type="tel" placeholder="Phone number" value={phone} onChange={e => setPhoneValue(e.target.value)} />
            </Form.Group>
          </Col>
        </Row>
        {/* <Row>
          <Col sm={4} className="mb-3">
          <Form.Group controlId="showOnWeb">
            <Form.Check type="checkbox" label="Show on web" checked={true} />
          </Form.Group>
          </Col>
        </Row> */}
          <div className="mt-3">
            <Button variant="primary" type="submit">{ mode == 'new' ? 'Save' : 'Update' }</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};
