import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faHome } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb, Button, Card, Form, Row, Col } from '@themesberg/react-bootstrap';
import { BASE_URL } from "../Constants";
import axios from "axios";

export default () => {
  const iframeCode = `<iframe width="80%" height="680" src="https://embed.stockbirdapp.com/1u26iom" title="Product locator" frameborder="0" allow="geolocation" allowfullscreen="" style="display: block; margin: 0 auto;"></iframe>`;
  // const directEmbed = `<div id="root" data-account-id="1u26iom" style="margin: 0 auto; width: 95%;"></div>\n<script src="https://embed.stockbirdapp.com/direct/sb.js"></script>\n<link href="https://embed.stockbirdapp.com/direct/sb.css" rel="stylesheet">`;

  const [account, setAccount] = useState();
  const [directEmbed, setDirectEmbed] = useState('');

  useEffect(() => {
    loadAccount();
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(directEmbed);
  }

  const loadAccount = () => {
    axios.get(`${BASE_URL}/account`)
      .then(res => {
        const accountData = res.data;
        setAccount(accountData);
        setDirectEmbed(`<div id="stockbird-root" data-account-id="${res.data.id}" style="margin: 0 auto; width: 95%;"></div>\n<script src="https://embed.stockbirdapp.com/direct/stockbird-1.0.1.js"></script>`);
        console.log('directEmbed: ', directEmbed);
      })
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
              <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                  <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                  <Breadcrumb.Item href={"#settings/general"}>Settings</Breadcrumb.Item>
                  <Breadcrumb.Item active>Embed Code</Breadcrumb.Item>
              </Breadcrumb>
              <h4>Embed Code</h4>
              <p className="mb-0">Your locator widget embed code.</p>
          </div>
      </div>

      <Row>
        <Col xs={12} xl={8}>
          <Card>
            <Card.Body>
              <span>
                If you would like to manually embed your store locator on any page, you can use the code below to do so.<br/><br/>
              </span>
                <code>
                  <Form.Control as="textarea" rows={4} value={directEmbed} readOnly>
                  </Form.Control>
                </code>
                <Row>&nbsp;</Row>
                <Button size="sm" onClick={()=>{ copyToClipboard() }}><FontAwesomeIcon icon={faClipboard} />&nbsp;Copy to clipboard</Button>
            
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
