import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb, Card, Row, Col } from '@themesberg/react-bootstrap';
import axios from "axios";
import { BASE_URL } from "../Constants";
import ShopifyPlans from "./components/ShopifyPlans";

// site: 'stockbird-test',
//         publishableKey: 'test_HZacu8nmcuoxw1xX1NGDoGfzBT9ouz4Kcdh'


export default () => {
  const [chargeBeeReady, setChargeBeeReady] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [accountDetails, setAccountDetails] = useState();
  const [requestInProgress, setRequestInProgress] = useState(false);


  useEffect(() => {
    getAccountSubscription();


    const el = document.createElement('script');
    el.onload = () => {
      window.Chargebee.init({
        "site": "stockbird",
        "publishableKey": "live_LWRcuPAUPtDaIFKtOucdZAodWcww2zYM9L"
        // "site": "stockbird-test",
        // "publishableKey": "test_HZacu8nmcuoxw1xX1NGDoGfzBT9ouz4Kcdh"
      });
      

      // setTimeout(() => {
        window.Chargebee.registerAgain();
        setChargeBeeReady(true);
      // }, 2000);

      // const cbInstance = window.Chargebee.getInstance();

      // cbInstance.setCheckoutCallbacks(function(cart) {
      //     return {
      //       loaded: function() {
      //         console.log("checkout opened");
      //       },
      //       close: function() {
      //         console.log("checkout closed");
      //       },
      //       success: function(hostedPageId) {
      //         console.log('SUCCESS');
      //         console.log(hostedPageId);
      //       },
      //       step: function(value) {
      //         // value -> which step in checkout
      //         console.log(value);
      //       }
      //     }
      // })
    };
    el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');
    document.body.appendChild(el);

  }, []);

  const shopifyPlanName = (planName) => {
    const plan = {
      name: '',
      price: '',
      locations: 0
    }

    switch(planName) {
      case 'Shopify_Stockbird_Standard':
        plan.name = 'Standard';
        plan.id = 'standard';
        plan.price = '$19 USD';
        plan.locations = 100;
        break;
      case 'Shopify_Stockbird_Pro':
        plan.name = 'Pro';
        plan.id = 'pro';
        plan.price = '$29 USD';
        plan.locations = 1000;
        break;
      case 'Shopify_Stockbird_Enterprise':
        plan.name = 'Enterprise';
        plan.id = 'enterprise';
        plan.price = '$49 USD';
        plan.locations = 5000;
        break;
      default:
        break;
    }

    return plan;
  }

  const getAccountSubscription = () => {
    axios.get(`${BASE_URL}/account/subscription`)
      .then(res => {
        const accountData = res.data;
        setAccountDetails(accountData);
        console.log('Account data: ', accountData);

        const cbInstance = window.Chargebee.getInstance();

        var cart = cbInstance.getCart();

        var customer = { 
          email: accountData.email,
          first_name: accountData.name, 
          last_name: "", 
          billing_address:{
            first_name: accountData.name, 
            last_name: "", 
            company: accountData.account.name,
            line1: accountData.account.address, 
            line2: accountData.account.address2,
            city: accountData.account.city,
            state: accountData.account.state,
            zip: accountData.account.zip
          }
        };

        // Setting custom fields
        customer["sb_acct"] = accountData.account.id;

        cart.setCustomer(customer);
      })
  }

  const Subscribed = (props) => {
    const { account } = props;
  }

  const subscribeShopify = (plan) => {

      setRequestInProgress(true);
      axios.post(`${BASE_URL}/shopify/subscribe`, { plan: plan, change: true })
          .then(res => {
              if (res.status === 200) {
                  const redirectUrl = res.data.redirect_url;
                  window.location = redirectUrl;
                  // next;

                  // history.push('/shopify/page');
              } else {
                  console.error('error');
                  alert('Error adding Stockbird plan to your Shopify account.')
              }
          })
          .catch((err) => {
              console.error(err);
          })
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
              <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                  <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                  <Breadcrumb.Item href={"#settings/general"}>Settings</Breadcrumb.Item>
                  <Breadcrumb.Item active>Subscription</Breadcrumb.Item>
              </Breadcrumb>
              <h4>Subscription</h4>
              <p className="mb-0">Your Stockbird subscription.</p>
          </div>
      </div>

      <Row>
        <Col xs={12} xl={8}>
          <Card>
            <Card.Body>
              { accountDetails && accountDetails.account && accountDetails.account.shopify_api_client_id ? (
                <>
                  <span>Your subscription is managed by Shopify.</span>
                  <br/>
                  <br/>
                  <ShopifyPlans requestInProgress={requestInProgress} currentPlan={shopifyPlanName(accountDetails.account.plan_id).id} subscribe={subscribeShopify} actionLabel={'Change to this plan'} />

                </>
              ) : (<></>) }
      
              { accountDetails && accountDetails.account.plan_id && !accountDetails.account.shopify_api_client_id ? (
                <>
                  <h5>Your Plan</h5>
                  <Row>
                    <Col>
                      <Row>{ accountDetails.account.plan_id }</Row>
                      <Row>Next billing date: { accountDetails.account.next_billing_at }</Row>
                    </Col>
                  </Row>
                </>) : (<></>) }
              { accountDetails && !accountDetails.account.plan_id && !accountDetails.account.shopify_api_client_id ? (
                <>
                  <h5>Plans</h5>
                  <Row>
                    <Col sm={4}>
                      <Card>
                        <Card.Body>
                          <Row className="pt-1 pb-3">
                            <h5>Standard 100</h5>
                            <h4>$19<span className="per_mo">/mo</span></h4>
                            Display up to 100 locations in your store locator.
                            </Row>
                          <Row>{ chargeBeeReady ? (
                            // <a href="javascript:void(0)" data-cb-type="checkout" data-cb-item-0="Stockbird-Standard-USD-Monthly" data-cb-item-0-quantity="1" >subscribe</a>
                            <a href="https://stockbird.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Stockbird-Standard-USD-Monthly&subscription_items[quantity][0]=1" target="_blank" className="btn btn-primary">Subscribe</a>  
                          ) : (null)}
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm={4}>
                        <Card>
                          <Card.Body>
                            <Row className="pt-1 pb-3">
                              <h5>Pro 1,000</h5>
                              <h4>$29<span className="per_mo">/mo</span></h4>
                              Display up to 1,000 locations in your store locator.
                            </Row>
                            <Row>{ chargeBeeReady ? (
                              <a href="https://stockbird.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Stockbird-Pro-USD-Monthly&subscription_items[quantity][0]=1" target="_blank" className="btn btn-primary">Subscribe</a>
                              // <a href="javascript:void(0)" data-cb-type="checkout" data-cb-item-0="Stockbird-Pro-USD-Monthly" data-cb-item-0-quantity="1" className="btn btn-primary">Subscribe</a>
                            ) : (null)}
                            </Row>
                          </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={4}>
                        <Card>
                          <Card.Body>
                            <Row className="pt-1 pb-3">
                              <h5>Enterprise 5,000</h5>
                              <h4>$49<span className="per_mo">/mo</span></h4>
                              Display up to 5,000 locations in your store locator.
                            </Row>
                            <Row>{ chargeBeeReady ? (
                              <a href="https://stockbird.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Stockbird-Enterprise-USD-Monthly&subscription_items[quantity][0]=1" target="_blank" className="btn btn-primary">Subscribe</a>
                              // <a href="javascript:void(0)" data-cb-type="checkout" data-cb-item-0="Stockbird-Enterprise-USD-Monthly" data-cb-item-0-quantity="1" className="btn btn-primary">Subscribe</a>
                            ) : (null)}
                            </Row>
                          </Card.Body>
                        </Card>
                    </Col>
                  </Row>
                </>
              ) : (<></>)}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
