import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Preloader from '../../components/Preloader';

import axios from "axios";
import { BASE_URL } from "../../Constants";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default () => {
    let history = useHistory();
    let query = useQuery();

    useEffect(() => {
        const chargeId = query.get('charge_id');
        const change = query.get('change');

        const token = localStorage.getItem('token');
        if (token && chargeId) {
            axios.interceptors.request.use(function (config) {
              config.headers.Authorization = `Bearer ${token}`;
          
              return config;
            });

            axios.post(`${BASE_URL}/shopify/subscribed`, { charge_id: chargeId })
                .then(res => {
                    // off to dashboard
                    if (change && change == 'true') {   // are we just changing our existing sub?
                        history.push('/settings/subscription');
                    } else {    // onboarding flow
                        history.push('/settings/shopify/page');
                    }
                })
                .catch((err) => {
                    console.error(err);
                    if (change && change == true) {
                        history.push('/settings/subscription');
                    } else {
                        history.push('/settings/shopify/page');
                    }
                })
        } else {
            alert('Error adding Stockbird.');
        }
    }, []);

    return (
        <>
            <Preloader />
            Redirecting to Stockbird Dashboard...
        </>
    );
}