import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Preloader from '../../components/Preloader';

import axios from "axios";
import { BASE_URL } from "../../Constants";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default () => {
    let history = useHistory();
    let query = useQuery();

    useEffect(() => {
        const token = query.get('t');
        if (token) {
            axios.interceptors.request.use(function (config) {
              config.headers.Authorization = `Bearer ${token}`;
          
              return config;
            });


            axios.get(`${BASE_URL}/me`)
                .then(res => {
                    if (res.status === 200) {
                        localStorage.setItem('token', token);
                        
                        localStorage.setItem('user.name', res.data.name);
                        localStorage.setItem('user.email', res.data.email);
                        localStorage.setItem('account.encodedId', res.data.account.encoded_id);
                        localStorage.setItem('account.shopify', true);
                        localStorage.setItem('account.shopifyShop', res.data.account.shopify_shop);

                        /*
                            if already subscribed, we need to skip this redirect
                        */

                            if (res.data.account.shopify_subscription_active) {
                                history.push('/dashboard');
                            } else {
                                history.push('/shopify/plan');
                            }

                    } else {
                        console.error('error');
                    }
                })
                .catch((err) => {
                    console.error(err);
                })
        } else {
            alert('Error adding Stockbird.');
        }
    }, []);

    return (
        <>
            <Preloader />
            Installing Stockbird...
        </>
    );
}