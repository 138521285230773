import React, { useEffect, useState } from "react";
import Preloader from '../../components/Preloader';
import { Form, Container, Image, Button, Breadcrumb, Card, Row, Col } from '@themesberg/react-bootstrap';

import axios from "axios";
import { BASE_URL } from "../../Constants";
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';

import L from '../../assets/img/brand/l.svg';
import sbFooter from '../../assets/img/brand/sb-footer.svg';
import { ToastContainer, toast } from 'react-toastify';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default () => {
    const history = useHistory();
    let query = useQuery();

    const [showPreloader, setShowPreloader] = useState(false);
    const [requestInProgress, setRequestInProgress] = useState(false);

    const [pageTitle, setPageTitle] = useState('');
    const [showLater, setShowLater] = useState(true);

    const [shopifyShop, setShopifyShop] = useState();

    useEffect(() => {
        const standalone = query.get('standalone');
        if (standalone && (standalone == false || standalone == 'false')) {
            setShowLater(false);
        }

        const shop = localStorage.getItem('account.shopifyShop');
        setShopifyShop(shop);
    }, []);

    const addPage = (title) => {
        // setShowPreloader(true);
        setRequestInProgress(true);

        axios.post(`${BASE_URL}/shopify/add-page`, { page_title: title })
            .then(res => {
                if (res.status === 200) {
                    toast('🎉 Store locator page added!', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })

                    setTimeout(() => {
                        if (shopifyShop) {
                            window.open(`https://${shopifyShop}/admin/pages`, "_blank");
                        }
                    }, 1000);
                    
                    setTimeout(() => {
                        history.push('/dashboard');
                    }, 5000);
                } else {
                    console.error('error');
                    setRequestInProgress(false);
                    alert('Error creating page on your Shopify store.')
                }
            })
            .catch((err) => {
                setRequestInProgress(false);
                console.error(err);
            })
    }

    return (
        <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
          {/* Same as */}
          <ToastContainer />
        { showPreloader ? (<Preloader show={true} />) : (
            <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                            <Breadcrumb.Item href={"#settings/general"}>Settings</Breadcrumb.Item>
                            <Breadcrumb.Item active>Shopify Page</Breadcrumb.Item>
                        </Breadcrumb>

                        <h4>Store Locator Page</h4>
                        <p className="mb-0">Add a store locator page to your Shopify shop.</p>
                    </div>
                </div>
                {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h4>Store Locator Page</h4>
                        <p className="mb-0">Add a store locator page to your Shopify shop.</p>
                    </div>
                </div> */}
        
                <Row>
                <Col xs={12} xl={8}>
                    <Card>
                    <Card.Body>
                        <Form>
                            <Row>
                                <span>
                                    Stockbird will automatically add a page with your store locator embedded. Below you can enter the page title, we'll create an unpublished page with your store locator embedded.<br/><br/>Note: You can manually do this at a later time.
                                </span>
                                <Col sm={8} className="mb-3 mt-3">
                                    <Form.Group id="title">
                                    <Form.Label>Page Title</Form.Label>
                                    <Form.Control required value={pageTitle} onChange={(e) => { setPageTitle(e.target.value) }} type="text" placeholder="Enter page title" />
                                    <div className="mt-2"><small>Ideas: "Store Locator", "Store Finder"</small></div>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="pt-2 pb-2">
                                <Col>
                                    <div className="mt-3">
                                        { showLater ? (
                                        <Button variant="outline-primary" onClick={() => { history.push('/dashboard') }} disabled={requestInProgress}>Skip this for now</Button>
                                        ) : (null) }
                                    </div>
                                </Col>
                                <Col>
                                    <div className="mt-3">
                                        <Button variant="primary" disabled={requestInProgress} onClick={() => { addPage(pageTitle) }}>Create Page</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                    </Card>
                </Col>
                </Row>
            </>
            )}
        </>
    );
}