import React , { useEffect, useState} from "react";
import { BASE_URL } from '../../Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb, Button, Card, Form, Table, Nav, Pagination, Row, Col, InputGroup } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import { Link } from 'react-router-dom';
import axios from 'axios';

export default () => {
    const [tags, setTags] = useState([]);
    const [selectedIndices, setSelectedIndices] = useState([]);

    // Pagination
    const [currentPage, setCurrentPage] = useState();
    const [lastPage, setLastPage] = useState();
    const [perPage, setPerPage] = useState();
    const [total, setTotal] = useState();
    const [from, setFrom] = useState();
    const [to, setTo] = useState();

    useEffect(() => {
      const getPage = new URLSearchParams(window.location.search).get('page');
      loadTags(getPage);
    }, []);

    const loadTags = (pageNumber, query) => {
      axios.get(`${BASE_URL}/tags?page=${pageNumber}`)
        .then(res => {
          const tags = res.data.data;
          console.log(tags);
          setTags(res.data.data);

          setCurrentPage(res.data.current_page);
          setPerPage(res.data.perPage);
          setTotal(res.data.total);
          setFrom(res.data.from);
          setTo(res.data.to);
          setLastPage(res.data.last_page);
        })
    }

    const toggleSelectAll = () => {
      if (selectedIndices.length > 0) {
        // select none
        setSelectedIndices([]);
      } else {
        // select all
        setSelectedIndices(tags.map(t => t.id))
        console.log(tags.map(t => t.id));
        console.log(selectedIndices);
      }
    }


    const TagsTable = (props) => {
        const { tags, selected } = props;

        const TableRow = (props) => {
            const { id, title } = props;
      
            return (
                <tr>
                    <td>
                      <Form.Check id="checkbox1" htmlFor="checkbox1" checked={selected} />
                    </td>
                    <td>
                        {title}
                    </td>
                </tr>
            );
        };
      
        return (
            <>
            <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="pb-0">
                  <Row sm={4} className="mb-0">
                    <Col>
                      {/* <Form.Group> */}
                        <Form.Select disabled={selectedIndices.length < 1}>
                          <option defaultValue>Bulk actions</option>
                          <option>One</option>
                          <option>Two</option>
                          <option>Three</option>
                        </Form.Select>
                    </Col>
                    <Col>
                      <Button variant="primary" disabled={selectedIndices.length < 1}>Apply</Button>
                    {/* </Form.Group> */}
                    </Col>
                  </Row>
                  <Row>&nbsp;</Row>
                <Table responsive className="table-centered table-hover table-nowrap rounded mb-0">
                    <thead className="thead-light">
                    <tr>
                        <th className="border-0"><Form.Check id="checkbox-selectall" onChange={() => {toggleSelectAll()}} checked={false} /></th>
                        <th className="border-0">Title</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tags.map(t => <TableRow key={`tags-${t.id}`} {...t} selected={selectedIndices.includes(t.id)} />)}
                    </tbody>
                </Table>
                <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                    <Nav>
                        <Pagination className="mb-2 mb-lg-0">
                          {
                            (currentPage > 1) ? 
                              (
                                <Pagination.Prev onClick={() => {loadTags(currentPage-1)}}>
                                    Previous
                                </Pagination.Prev>
                              ) : (<></>)
                          }
                          {
                            [...Array(lastPage).keys()].map((p, index) => (<Pagination.Item onClick={() => {loadTags(index+1)}} active={currentPage === (index+1)}>{index+1}</Pagination.Item>))
                          }
                          {
                            (currentPage < lastPage) ?
                              (
                                <Pagination.Next onClick={() => {loadTags(currentPage+1)}}>
                                  Next
                                </Pagination.Next>
                              ) : (<></>)
                          }
                        
                        </Pagination>
                    </Nav>
                    <small className="fw-bold">
                        Showing <b>{from}-{to}</b> out of <b>{total}</b> tags
                    </small>
                    </Card.Footer>
                </Card.Body>
            </Card>
        </>
        );
      };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Tags</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Tags</h4>
          <p className="mb-0">Tag your locations.</p>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup>
          </Col>
          <Col xs={4} className="ps-md-0 text-end">
            <div className="mt-3">
                {/* <Button as={HashLink} to="#download" variant="outline-white" className="ms-3"><FontAwesomeIcon icon={faDownload} className="me-1" /> Download</Button> */}
                <Button variant="primary" as={Link} to={Routes.NewLocation.path} className="text-dark me-3"><FontAwesomeIcon icon={faPlusCircle} className="me-1" /> Add New</Button>
            </div>
          </Col>
        </Row>
      </div>

      <TagsTable tags={tags} />
    </>
  );
};
