import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import GoogleMapReact from 'google-map-react';
import { Card, Col, Row, ProgressBar } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { faChrome, faEdgeLegacy, faFirefoxBrowser, faInternetExplorer, faSafari } from '@fortawesome/free-brands-svg-icons';
import { BASE_URL } from "../Constants";
import { faCircleQuestion, faComputerMouse, faDesktop, faMobile, faMobileButton, faMobileScreenButton } from "@fortawesome/free-solid-svg-icons";

export default () => {
    const defaultProps = {
        center: {
          lat: 38.7244252,
          lng:-98.7013072
        },
        zoom: 4
    };

    const [deviceType, setDeviceType] = useState({
      mobile: 0,
      desktop: 0
    })

    const [browsers, setBrowsers] = useState({
      chrome: 0,
      firefox: 0,
      ie: 0,
      safari: 0,
      edge: 0,
      unknown: 0
    });

   const [heatMapData, setHeatMapData] = useState({    
        positions: [
          { lat: 40.0388765, lng: -82.8748301 },
          { lat: 37.6032972, lng: -77.2635038 },
          { lat: 34.420334, lng: -119.7107494 },
        ],
        options: {   
          radius: 20,   
          opacity: 0.6
        }
    });

    useEffect(() => {
      loadDashboard();
    }, []);

    const loadDashboard = () => {
      let url = `${BASE_URL}/dashboard`;
      
      axios.get(url)
        .then(res => {
          console.log(res.data);
          setBrowsers(res.data.browsers);
          setDeviceType(res.data.device_types);
          setHeatMapData({ positions: res.data.locations,
            options: {   
              radius: 20,   
              opacity: 0.6
            } })
        })
    }

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <h4>Dashboard</h4>
                    <p className="mb-0">Usage statistics from the last 30 days rolling</p>
                </div>
            </div>
            <Row>
                <Col xs={12} xl={8}>
                <Card>
                    <Card.Body>
                        <h5>Search Location Heatmap</h5>
                        <div style={{ height: '400px', width: '100%' }}>
                            <GoogleMapReact          
                                // ref={(el) => this._googleMap = el}          
                                bootstrapURLKeys={{ key: 'AIzaSyC7BhJXnGGR0K2MQjyoCci_WXGMPL_YjSY' }}      
                                defaultCenter={defaultProps.center}          
                                defaultZoom={defaultProps.zoom}          
                                heatmapLibrary={true}          
                                heatmap={heatMapData}   
                                // onClick={this.onMapClick.bind(this)}        
                                ></GoogleMapReact>
                        </div>
                        <br/>
                        <BrowserWidget browsers={browsers} />
                        <br/>
                        <DeviceTypeWidget deviceType={deviceType} />
                    </Card.Body>
                </Card>
                </Col>
            </Row>
        </>
    );
}

export const DeviceTypeWidget = (props) => {
  const Progress = (props) => {
    const { title, percentage, icon, color, last = false } = props;
    const extraClassName = last ? "" : "mb-2";

    return (
      <Row className={`align-items-center ${extraClassName}`}>
        <Col xs="auto">
          <span className={`icon icon-md text-${color}`}>
            <FontAwesomeIcon icon={icon} className="me-1" />
          </span>
        </Col>
        <Col>
          <div className="progress-wrapper">
            <div className="progress-info">
              <h6 className="mb-0">{title}</h6>
              <small className="fw-bold text-dark">
                <span>{percentage.toFixed(0)} %</span>
              </small>
            </div>
            <ProgressBar variant={color} now={percentage} min={0} max={100} />
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header className="border-bottom border-light">
        <h5 className="mb-0">Device Type</h5>
      </Card.Header>
      <Card.Body>
        <Progress title="Mobile" color="purple" icon={faMobileButton} percentage={props.deviceType.mobile * 100} />
        <Progress title="Desktop" color="danger" icon={faComputerMouse} percentage={props.deviceType.desktop * 100} />
      </Card.Body>
    </Card>
  );
}

export const BrowserWidget = (props) => {
    const Progress = (props) => {
      const { title, percentage, icon, color, last = false } = props;
      const extraClassName = last ? "" : "mb-2";
  
      return (
        <Row className={`align-items-center ${extraClassName}`}>
          <Col xs="auto">
            <span className={`icon icon-md text-${color}`}>
              <FontAwesomeIcon icon={icon} className="me-1" />
            </span>
          </Col>
          <Col>
            <div className="progress-wrapper">
              <div className="progress-info">
                <h6 className="mb-0">{title}</h6>
                <small className="fw-bold text-dark">
                  <span>{percentage.toFixed(0)} %</span>
                </small>
              </div>
              <ProgressBar variant={color} now={percentage} min={0} max={100} />
            </div>
          </Col>
        </Row>
      );
    };
  
    return (
      <Card border="light" className="shadow-sm">
        <Card.Header className="border-bottom border-light">
          <h5 className="mb-0">Browsers</h5>
        </Card.Header>
        <Card.Body>
          <Progress title="Chrome" color="purple" icon={faChrome} percentage={props.browsers.chrome * 100} />
          <Progress title="Firefox" color="danger" icon={faFirefoxBrowser} percentage={props.browsers.firefox * 100} />
          <Progress title="Edge" color="tertiary" icon={faEdgeLegacy} percentage={props.browsers.edge * 100} />
          <Progress title="Safari" color="info" icon={faSafari} percentage={props.browsers.safari * 100} />
          <Progress title="Internet Explorer" color="purple" icon={faInternetExplorer} percentage={props.browsers.ie * 100} />
          <Progress last title="Unknown" color="gray" icon={faCircleQuestion} percentage={props.browsers.unknown * 100} />
        </Card.Body>
      </Card>
    );
  };